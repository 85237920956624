<template>
  <div class="record_list">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :offset="100"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <div
        v-for="(item, index) in format_list"
        :key="index"
        class="year_box">
        <div class="year">{{item[0]}}年</div>
        <div
          v-for="(item2, index2) in item[1]"
          :key="index2"
          class="item_box">
          <div class="date">{{format_date(item2.record_date, 'MM-dd')}}</div>
          <div class="line_box">
            <div class="dot"></div>
            <div class="line"></div>
          </div>
          <!-- 按天归类 -->
          <div class="day_box">
            <div
              v-for="(item3, index3) in item2.records"
              :key="index3"
              class="content_box">
              <!-- 诊断 -->
              <template v-if="item3.type === 2">
                <div class="title_box" style="margin-bottom: -7px;">
                  <div>
                    <div class="title">{{item3.diag.diagnosis_name}}</div>
                    <div class="doctor">医生 {{item3.diag.doctor_name}}</div>
                  </div>
                  <div class="right">诊断</div>
                </div>
                <template v-if="item3.diag.drugs && item3.diag.drugs.length > 0">
                  <div class="title_box mt">
                    <div class="title">用药</div>
                  </div>
                  <div class="items">
                    <div
                      v-for="(item4, index4) in item3.diag.drugs"
                      :key="index4"
                      class="item">
                      <div>
                        <div class="name">{{item4.medi_name}}</div>
                        <div class="content">{{item4.freq_name + ' ' + item4.usage}}</div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="item3.diag.labs && item3.diag.labs.length > 0">
                  <div class="title_box mt">
                    <div class="title">检验项目</div>
                    <div class="right" @click="labs(item3.diag.labs[0])">查看详情</div>
                  </div>
                  <div class="items">
                    <div
                      v-for="(item4, index4) in item3.diag.labs"
                      :key="index4"
                      class="item">
                      <div>
                        <div class="name">{{item4.report_name}}</div>
                        <div class="content">发现 <label>{{item4.count}}</label> 项异常</div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="item3.diag.exams && item3.diag.exams.length > 0">
                  <div class="title_box mt">
                    <div class="title">检查项目</div>
                  </div>
                  <div
                    v-for="(item4, index4) in item3.diag.exams"
                    :key="index4"
                    @click="exams(item4)"
                    class="item">
                    <div>
                      <div class="name">{{item4.report_name}}</div>
                      <div class="content">{{item4.desc}}</div>
                    </div>
                    <van-icon name="arrow" color="#C9C9C9" size="18" />
                  </div>
                </template>
              </template>
              <!-- 检验 -->
              <template v-if="item3.type === 3">
                <div class="title_box center">
                  <div>
                    <div class="title">检验</div>
                    <div class="doctor">医生 {{item3.labs[0].doctor_name}}</div>
                  </div>
                 <div class="right" @click="labs(item3.labs[0])">查看详情</div>
                </div>
                <div class="items">
                  <div
                    v-for="(item4, index4) in item3.labs"
                    :key="index4"
                    class="item">
                    <div>
                      <div class="name">{{item4.report_name}}</div>
                      <div class="content">发现 <label>{{item4.count}}</label> 项异常</div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 检查 -->
              <template v-if="item3.type === 4">
                <div class="title_box">
                  <div class="title">检查</div>
                </div>
                <div
                  v-for="(item4, index4) in item3.exams"
                  :key="index4"
                  @click="exams(item4)"
                  class="item">
                  <div>
                    <div class="name">{{item4.report_name}}</div>
                    <div class="content">{{item4.desc}}</div>
                  </div>
                  <van-icon name="arrow" color="#C9C9C9" size="18" />
                </div>
              </template>
              <!-- 用药 -->
              <template v-if="item3.type === 5">
                <div class="title_box">
                  <div class="title">用药</div>
                </div>
                <div class="items">
                  <div
                    v-for="(item4, index4) in item3.drugs"
                    :key="index4"
                    class="item">
                    <div>
                      <div class="name">{{item4.medi_name}}</div>
                      <div class="content">{{item4.freq_name + ' ' + item4.usage}}</div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { format_date } from "../../../utils/format"
import { record_list } from "../service"

export default {
  data () {
    return {
      loading: false,
      timer: null,
      finished: false,
      refreshing: false,
      error: false,
      page_size: 15,
      page: 0,
      list: [],
      current_list: [],
      total: 0,
    }
  },
  computed: {
    type () {
      return this.$route.params.type
    },
    format_list () {
      const m = new Map()
      for (let item of this.list) {
        const year = new Date(item.record_date).getFullYear()
        if (m.has(year)) {
          m.get(year).push(item)
        } else {
          m.set(year, [item])
        }
      }
      return m
    }
  },
  watch: {
    type () {
      this.change()
    },
  },
  components: {
  },
  created () {
    console.log(this.type)
    this.get_list()
  },
  methods: {
    format_date,
    async onLoad () {
      if (this.timer) clearTimeout(this.timer)
      if (this.refreshing) {
        this.loading = true
        this.list = []
        this.current_list = []
        this.page = -1
        this.refreshing = false
      }
      if((this.list.length > 0 && this.list.length === this.total) || this.error || this.total === 0) {
        this.finished = true
        this.error = false
        this.loading = false
        return
      }

      this.timer = setTimeout(() => {
        this.page = this.page + 1
        this.get_list()
      }, 1000)
    },
    onRefresh() {
      this.finished = false
      this.loading = false
      this.onLoad()
    },
    async get_list () {
      this.loading = true
      const {type, page_size, page} = this
      const params = {type, page, page_size}
      try {
        const {data} = await record_list(params)
        if (params.type !== this.type) return
        this.current_list = data.data
        this.total = data.total_elements || 0
        this.list = this.list.concat(this.current_list)
      } catch(e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    change () {
      this.finished = false
      this.loading = false
      this.page = 0
      this.list = []
      this.get_list()
    },
    labs (item) {
      this.$router.push({path: '/health-record-lab', query: {id: item.id}})
    },
    exams (item) {
      this.$router.push({path: '/health-record-exam', query: {id: item.id}})
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.year_box {
  margin-top: 15px;
  padding: 0px 16px 0px 0px;
  &:first-child {
    margin-top: 25px;
  }
  & .year {
    font-size: 18px;
    font-weight: 500;
    color: #292929;
    padding-left: 16px;
  }
}
.item_box {
  display: flex;
  &:last-child {
    margin-bottom: -10px;
    & .line_box .line {
      display: none;
    }
  }
  & .date {
    font-size: 14px;
    font-weight: 500;
    color: #292929;
    margin-top: 11px;
    width: 54px;
    text-align: right;
  }
}
.line_box {
  margin-left: 7px;
  position: relative;
  top: 12px;
  & .dot {
    width: 13px;
    height: 13px;
    background: #FFFFFF;
    border: 3px solid #6D79D5;
    border-radius: 50%;
    position: relative;
    z-index: 2;
  }
  & .line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 6px;
    width: 1px;
    background: #EAEAEA;
  }
}
.day_box {
  flex: 1;
  overflow: hidden;
  margin-bottom: 10px;
}
.content_box {
  background: #fff;
  padding: 12px 14px 18px;
  border-radius: 8px;
  margin-left: 14px;
  margin-bottom: 15px;
  & .title_box {
    display: flex;
    &.mt {
      margin-top: 20px;
    }
    &.center {
      align-items: center;
    }
    &>div:first-child {
      flex: 1;
      overflow: hidden;
    }
    & .title {
      font-weight: 500;
      line-height: 20px;
      color: #292929;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    & .doctor {
      font-size: 12px;
      line-height: 17px;
      color: #292929;
    }
    & .right {
      color: #0088FF;
      line-height: 20px;
    }
  }
  & .item {
    display: flex;
    align-items: center;
    background: #F4F4FF;
    border-radius: 8px;
    padding: 14px 3px 14px 10px;
    margin-top: 8px;
    &>div:first-child {
      flex: 1;
      overflow: hidden;
    }
    & .name {
      font-weight: 500;
      line-height: 20px;
      color: #292929;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    & .content {
      font-size: 12px;
      line-height: 18px;
      color: #292929;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      & label {
        color: #0088FF;
      }
    }
  }
  & .items {
    margin-top: 8px;
    background: #F4F4FF;
    border-radius: 8px;
    & .item {
      margin-top: 0px;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 0px;
        height: 1px;
        background: rgba(193, 193, 193, 0.5);
      }
      &:last-child:after {
        display: none;
      }
    }
  }
}
</style>
